<template>
  <div class="pb-1 group-edit">
    <b-row>
      <b-col cols="6" v-for="option in options">
        <b-form-checkbox
          name="kioskToken"
          :value="option.kioskToken"
          v-model="rowData.kioskTokens"
          class="mt-1 ml-1 mr-1"
          :disabled="isEdit"
        >
          {{ option.kioskName }}
        </b-form-checkbox>
      </b-col>
      <b-col v-if="options.length === 0">
        <b-form-checkbox
          name="kioskToken"
          class="mt-1 ml-1 mr-1"
          disabled
        >
          {{ $t('kiosk.device_not_found') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import common from '@/common';

export default {
  name: 'SlotKiosk',
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    rowData: { type: Object },
    facilityId: { type: Number },
    platform: { type: String },
    isEdit: { type: Boolean }
  },
  data() {
    return {
      options: [],
    }
  },
  watch: {
    facilityId: function (facilityID) {
      this.rowData.kioskTokens = []
      this.options = []
      this.getOptions(facilityID)
    },
    platform: function (platform) {
      this.rowData.kioskTokens = []
      this.options = []
      this.getOptions(this.facilityId)
    },
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    getOptions: function () {
      const that = this
      const facilityID = this.facilityId ?? 0
      const platform = this.platform ?? ''
      if (!facilityID || !platform) return

      this.$requestWehealth({
        url: '/kioskupdate/GetKioskList',
        method: 'get',
        params: {
          facilityID,
          platform,
        },
      }).then(res => {
        this.options = res.data.kiosks
        if(common.config.is_request_mock && this.isEdit) {
          that.$set(that.rowData, 'kioskTokens', [this.options[0].kioskToken])
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
